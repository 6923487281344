import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

// Define the shape of your cache data
interface CacheData {
    staff: Array<{
        id: number
        firstName: string
        lastName: string
        hasLogin: boolean
        isArchived: boolean
        roleId: number
    }>
    roles: Array<{ id: number; name: string; isArchived: boolean }>
    contacts: Array<{
        id: number
        firstName: string
        lastName: string
        organisationName: string
    }>
    costCentres: Array<{ id: number; name: string }>
    invoices: Array<{
        id: number
        ref: string
        projectId: number
        dueDate: Date
        contactId: number
        issueDate: Date
    }>
    organisationReports: Array<{ id: number; name: string; type: string }>
    organisationSubscriptions: any[]
    organisations: any[]
    suppliers: Array<{ id: number; name: string }>
    resourceScheduleReports: Array<{ id: number; name: string }>
    revenueForecastReports: Array<{ id: number; name: string }>
    projects: Array<{
        id: number
        name: string
        jobNumber: string
        organisationId: number
        costCentreId: number
        status: string
        ownerId: number
        contactId: number
        primaryContactId: number
    }>
}

type FilterFunction<T> = (item: T) => boolean
type SortFunction<T> = (a: T, b: T) => number

interface CacheStore {
    cache: CacheData
    setCache: (newCache: CacheData) => void
    updateCache: <K extends keyof CacheData>(key: K, data: CacheData[K]) => void
    getItemById: <K extends keyof CacheData>(
        key: K,
        id: number
    ) => CacheData[K][number] | undefined
    deleteItem: <K extends keyof CacheData>(key: K, id: number) => void
    clear: () => void
    queryCache: <K extends keyof CacheData>(
        key: K,
        filter?: FilterFunction<CacheData[K][number]>,
        sort?: SortFunction<CacheData[K][number]>
    ) => CacheData[K]
}

export const useGlobalCache = create<CacheStore>()(
    subscribeWithSelector((set, get) => ({
        cache: {} as CacheData,
        setCache: (newCache) => set({ cache: newCache }),
        updateCache: (key, data) =>
            set((state) => ({ cache: { ...state.cache, [key]: data } })),
        getItemById: (key, id) => {
            const items = get().cache[key] as any[]
            return items.find((item) => item.id === id)
        },
        deleteItem: (key, id) =>
            set((state) => ({
                cache: {
                    ...state.cache,
                    [key]: (state.cache[key] as any[]).filter(
                        (item) => item.id !== id
                    ),
                },
            })),
        clear: () => set({ cache: {} as CacheData }),
        queryCache: (key, filter, sort) => {
            let result = [...get().cache[key]]

            if (filter) {
                result = result.filter(filter)
            }

            if (sort) {
                result.sort(sort)
            }

            return result
        },
    }))
)
