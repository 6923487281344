var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * <TextareaAutosize />
 */

import React from 'react';
import PropTypes from 'prop-types';
import calculateNodeHeight from './calculateNodeHeight';

var noop = function noop() {};

var TextareaAutosize = function (_React$Component) {
  _inherits(TextareaAutosize, _React$Component);

  function TextareaAutosize(props) {
    _classCallCheck(this, TextareaAutosize);

    var _this = _possibleConstructorReturn(this, _React$Component.call(this, props));

    _this._onRootDOMNode = function (node) {
      _this._rootDOMNode = node;
      if (_this.props.inputRef) _this.props.inputRef(node);
    };

    _this._onChange = function (event) {
      if (!_this._controlled) {
        _this._resizeComponent();
      }
      var _this$props = _this.props,
          valueLink = _this$props.valueLink,
          onChange = _this$props.onChange;

      if (valueLink) {
        valueLink.requestChange(event.target.value);
      } else {
        onChange(event);
      }
    };

    _this._resizeComponent = function () {
      if (!_this._rootDOMNode) {
        return;
      }

      var _calculateNodeHeight = calculateNodeHeight(_this._rootDOMNode, _this.props.useCacheForDOMMeasurements, _this.props.rows || _this.props.minRows, _this.props.maxRows),
          height = _calculateNodeHeight.height,
          minHeight = _calculateNodeHeight.minHeight,
          maxHeight = _calculateNodeHeight.maxHeight;

      if (_this.state.height !== height || _this.state.minHeight !== minHeight || _this.state.maxHeight !== maxHeight) {
        _this.setState({ height: height, minHeight: minHeight, maxHeight: maxHeight });
      }
    };

    _this.state = {
      height: props.style && props.style.height || 0,
      minHeight: -Infinity,
      maxHeight: Infinity
    };

    _this._controlled = typeof props.value === 'string';
    return _this;
  }

  TextareaAutosize.prototype.render = function render() {
    var _props = this.props,
        valueLink = _props.valueLink,
        _minRows = _props.minRows,
        _maxRows = _props.maxRows,
        _onHeightChange = _props.onHeightChange,
        _useCacheForDOMMeasurements = _props.useCacheForDOMMeasurements,
        _inputRef = _props.inputRef,
        props = _objectWithoutProperties(_props, ['valueLink', 'minRows', 'maxRows', 'onHeightChange', 'useCacheForDOMMeasurements', 'inputRef']);

    if ((typeof valueLink === 'undefined' ? 'undefined' : _typeof(valueLink)) === 'object') {
      props.value = valueLink.value;
    }

    props.style = _extends({}, props.style, {
      height: this.state.height
    });

    var maxHeight = Math.max(props.style.maxHeight || Infinity, this.state.maxHeight);

    if (maxHeight < this.state.height) {
      props.style.overflow = 'hidden';
    }

    return React.createElement('textarea', _extends({}, props, {
      onChange: this._onChange,
      ref: this._onRootDOMNode
    }));
  };

  TextareaAutosize.prototype.componentDidMount = function componentDidMount() {
    this._resizeComponent();
    window.addEventListener('resize', this._resizeComponent);
  };

  TextareaAutosize.prototype.componentWillReceiveProps = function componentWillReceiveProps() {
    // Re-render with the new content then recalculate the height as required.
    this._clearNextFrame();
    this._onNextFrameActionId = onNextFrame(this._resizeComponent);
  };

  TextareaAutosize.prototype.componentDidUpdate = function componentDidUpdate(prevProps, prevState) {
    // Invoke callback when old height does not equal to new one.
    if (this.state.height !== prevState.height) {
      this.props.onHeightChange(this.state.height);
    }
  };

  TextareaAutosize.prototype.componentWillUnmount = function componentWillUnmount() {
    // Remove any scheduled events to prevent manipulating the node after it's
    // been unmounted.
    this._clearNextFrame();
    window.removeEventListener('resize', this._resizeComponent);
  };

  TextareaAutosize.prototype._clearNextFrame = function _clearNextFrame() {
    if (this._onNextFrameActionId) {
      clearNextFrameAction(this._onNextFrameActionId);
    }
  };

  /**
   * Put focus on a <textarea /> DOM element.
   */
  TextareaAutosize.prototype.focus = function focus() {
    this._rootDOMNode.focus();
  };

  /**
   * Shifts focus away from a <textarea /> DOM element.
   */


  TextareaAutosize.prototype.blur = function blur() {
    this._rootDOMNode.blur();
  };

  _createClass(TextareaAutosize, [{
    key: 'value',


    /**
     * Read the current value of <textarea /> from DOM.
     */
    get: function get() {
      return this._rootDOMNode.value;
    }

    /**
     * Set the current value of <textarea /> DOM node.
     */
    ,
    set: function set(val) {
      this._rootDOMNode.value = val;
    }

    /**
     * Read the current selectionStart of <textarea /> from DOM.
     */

  }, {
    key: 'selectionStart',
    get: function get() {
      return this._rootDOMNode.selectionStart;
    }

    /**
     * Set the current selectionStart of <textarea /> DOM node.
     */
    ,
    set: function set(selectionStart) {
      this._rootDOMNode.selectionStart = selectionStart;
    }

    /**
     * Read the current selectionEnd of <textarea /> from DOM.
     */

  }, {
    key: 'selectionEnd',
    get: function get() {
      return this._rootDOMNode.selectionEnd;
    }

    /**
     * Set the current selectionEnd of <textarea /> DOM node.
     */
    ,
    set: function set(selectionEnd) {
      this._rootDOMNode.selectionEnd = selectionEnd;
    }
  }]);

  return TextareaAutosize;
}(React.Component);

TextareaAutosize.propTypes = {
  /**
   * Current textarea value.
   */
  value: PropTypes.string,

  /**
   * Callback on value change.
   */
  onChange: PropTypes.func,

  /**
   * Callback on height changes.
   */
  onHeightChange: PropTypes.func,

  /**
   * Try to cache DOM measurements performed by component so that we don't
   * touch DOM when it's not needed.
   *
   * This optimization doesn't work if we dynamically style <textarea />
   * component.
   */
  useCacheForDOMMeasurements: PropTypes.bool,

  /**
   * Minimal numbder of rows to show.
   */
  rows: PropTypes.number,

  /**
   * Alias for `rows`.
   */
  minRows: PropTypes.number,

  /**
   * Maximum number of rows to show.
   */
  maxRows: PropTypes.number,

  /**
   * Allows an owner to retrieve the DOM node.
   */
  inputRef: PropTypes.func
};
TextareaAutosize.defaultProps = {
  onChange: noop,
  onHeightChange: noop,
  useCacheForDOMMeasurements: false
};
export default TextareaAutosize;


function onNextFrame(cb) {
  if (window.requestAnimationFrame) {
    return window.requestAnimationFrame(cb);
  }
  return window.setTimeout(cb, 1);
}

function clearNextFrameAction(nextFrameId) {
  if (window.cancelAnimationFrame) {
    window.cancelAnimationFrame(nextFrameId);
  } else {
    window.clearTimeout(nextFrameId);
  }
}