import React, { useState, useEffect } from 'react'
import LayoutStore from '../../State/LayoutStore'
import { makeRequest } from '../../Queries/makeRequest'

export default ({ staff }) => {
    const [inviteStatus, setInviteStatus] = useState('loading')
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        const fetchInviteStatus = async () => {
            if (staff.workosInviteId) {
                try {
                    const response = await makeRequest({
                        path: `${process.env.REACT_APP_NODE_SERVER_URL}/auth/invite-status`,
                        method: 'post',
                        data: {
                            inviteId: staff.workosInviteId,
                            staffId: staff.id,
                        },
                    })
                    setInviteStatus(response?.data?.status)
                    setErrorMessage('') // Clear any previous error message
                } catch (error) {
                    console.log('herere')
                    console.error('Error fetching invite status:', error)
                    setInviteStatus('error')
                    setErrorMessage(
                        error.message ||
                            'An error occurred while fetching invite status'
                    )
                }
            }
        }

        fetchInviteStatus()
    }, [staff.workosInviteId, staff.id])
    if (inviteStatus === 'loading' && staff.workosInviteId) {
        return (
            <button
                className="btn btn-sm cursor-default bg-[#eee] hover:bg-[#eee] hover:border-[inherit]"
                style={{ marginLeft: '1em' }}
                disabled
            >
                Loading...
            </button>
        )
    }
    if (inviteStatus === 'sending') {
        return (
            <button
                className="btn btn-sm cursor-default bg-[#eee] hover:bg-[#eee] hover:border-[inherit]"
                style={{ marginLeft: '1em' }}
                disabled
            >
                Sending...
            </button>
        )
    }
    return (
        <div className="inline-block relative">
            <button
                className="btn btn-sm btn-default"
                style={{ marginLeft: '1em' }}
                onClick={async () => {
                    setInviteStatus('sending')
                    setErrorMessage('') // Clear any previous error message
                    try {
                        const response = await makeRequest({
                            path: `${process.env.REACT_APP_NODE_SERVER_URL}/auth/invite`,
                            method: 'post',
                            data: { staffId: staff.id, email: staff.email },
                        })
                        const invite = response?.data
                        if (invite) {
                            await staff.update({ workosInviteId: invite?.id })
                            setInviteStatus('pending')
                        } else {
                            throw new Error('No invite data received')
                        }
                    } catch (error) {
                        console.error(
                            'Error sending invite:',
                            error,
                            error.data,
                            error.response
                        )
                        setInviteStatus('error')
                        setErrorMessage(
                            error?.response?.data?.message ||
                                error.message ||
                                'An error occurred while sending the invite'
                        )
                    }
                }}
            >
                Invite Staff Member
            </button>
            {!['loading', 'sending', 'error'].includes(inviteStatus) ? (
                <div
                    className="text-xs absolute text-center w-full text-[grey]"
                    style={{ marginLeft: '1em' }}
                    disabled
                >
                    status: {inviteStatus}
                </div>
            ) : null}
            {errorMessage ? (
                <div className="text-xs absolute text-center w-full text-red-500">
                    Error: {errorMessage}
                </div>
            ) : null}
        </div>
    )
}
