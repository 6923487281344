import React, { useEffect, Suspense } from 'react'
import { observer } from 'mobx-react'
import './_styles/global.css'
import 'react-widgets/dist/css/react-widgets.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import LoadingSpinner from './Components/LoadingSpinner'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import useVisible from './visibleHook'
import SessionStore from './State/SessionStore'
import PageNotFound from './Pages/PageNotFound'

import { RouterProvider, createRouter } from '@tanstack/react-router'
import { routeTree } from './routeTree.gen.ts'
import { trpc } from './system/trpc.ts'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
            suspense: true,
            staleTime: 60 * 1000, //60 * 1000, //5 * 60 * 1000, // 5 minutes
            refetchOnWindowFocus: false, // default: true
            retry: (failureCount, error) => {
                // Check if the error is a timeout
                if (
                    error.code === 'ECONNABORTED' ||
                    error.message.includes('timeout')
                ) {
                    throw error
                    return false // Do not retry on timeouts
                }
                // You can add more conditions for other types of errors
                // For example, retry a maximum of 3 times for other errors
                return failureCount < 3
            },
        },
    },
})

export const router = createRouter({
    routeTree,
    defaultNotFoundComponent: () => {
        return <PageNotFound />
    },
    context: {
        queryClient,
        trpc: trpc,
    },
    defaultPreload: 'intent',
    defaultPreloadStaleTime: 0,
})

const isDev = process.env.REACT_APP_ENV === 'development'

// if (process.env.REACT_APP_ENV === 'production') {
posthog.init('phc_Burb304xExjYSqj20cLeUVWCO2DfMIZqELYlfzC89i6', {
    api_host: isDev ? 'https://app.posthog.com' : 'https://hog.coincraft.co',
    ui_host: 'https://app.posthog.com',
    session_recording: {
        maskAllInputs: false,
    },
})
// }

Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: 'https://e261b4f9137de175f786e96b25b0972f@o4505989495390208.ingest.sentry.io/4505989627707392',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                /^https:\/\/.*\.coincraft\.co\//,
            ],
        }),
        new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
        }),
        new posthog.SentryIntegration(
            posthog,
            'https://coincraft.sentry.io/',
            '4505989627707392'
        ),
    ],
    attachStacktrace: true,
    autoSessionTracking: true,
    sampleRate: 1,
    // Performance Monitoring
    enableTracing: true,
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: !isDev ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
// }

const App = observer(() => {
    const visibilityState = useVisible()
    useEffect(() => {
        SessionStore.checkIfUpdateIsAvailable()
    }, [visibilityState])

    return (
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={<LoadingSpinner />}>
                <div
                    className={
                        'coincraft-page-layout flex-col size-full flex absolute'
                    }
                >
                    <RouterProvider router={router} />
                </div>
            </Suspense>
            {/* {isDev ? <ReactQueryDevtools initialIsOpen={false} /> : null} */}
        </QueryClientProvider>
    )
})
export default Sentry.withProfiler(App)
