import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import { Button } from '@2/components/ui/button'
import { Input } from '@2/components/ui/input'
import { Avatar, AvatarFallback } from '@2/components/ui/avatar'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@2/components/ui/dropdown-menu'
import { Tabs, TabsList, TabsTrigger } from '@2/components/ui/tabs'
import { Search, MoreVertical } from 'lucide-react'
import {
    Command,
    CommandInput,
    CommandList,
    CommandEmpty,
    CommandGroup,
    CommandItem,
} from '@2/components/ui/command'
import { useGlobalCache } from '@2/cache'
import { getLabel } from '@2/utils/get-label'
import Fuse from 'fuse.js'
import { capitalCase } from 'change-case'
import pluralize from 'pluralize'

const generateSubstrings = (str) => {
    const substrings = new Set()
    for (let i = 0; i < str.length - 2; i++) {
        for (let j = i + 3; j <= str.length; j++) {
            substrings.add(str.slice(i, j).toLowerCase())
        }
    }
    return Array.from(substrings)
}

const HighlightExactMatch = ({
    text,
    searchTerm,
}: {
    text: string
    searchTerm: string
}) => {
    if (!searchTerm || !searchTerm.trim()) return <>{text}</>

    const searchSubstrings = useMemo(
        () => generateSubstrings(searchTerm),
        [searchTerm]
    )

    // Split the text into words, preserving spaces
    const words = text.split(/(\s+)/).map((part, index) => ({ part, index }))

    // Find matches
    let highlightIndices = []
    searchSubstrings.forEach((substring) => {
        let startIndex = 0
        while (true) {
            const matchIndex = text.toLowerCase().indexOf(substring, startIndex)
            if (matchIndex === -1) break
            highlightIndices.push([matchIndex, matchIndex + substring.length])
            startIndex = matchIndex + 1
        }
    })

    // Merge overlapping highlights
    highlightIndices.sort((a, b) => a[0] - b[0])
    const mergedIndices = highlightIndices.reduce((acc, curr) => {
        if (acc.length === 0 || curr[0] > acc[acc.length - 1][1]) {
            acc.push(curr)
        } else {
            acc[acc.length - 1][1] = Math.max(acc[acc.length - 1][1], curr[1])
        }
        return acc
    }, [])

    // Apply highlights
    let highlightedPart = []
    let currentIndex = 0
    words.forEach(({ part, index }) => {
        const partStart = text.indexOf(part, currentIndex)
        const partEnd = partStart + part.length
        let highlightedWord = []
        let lastIndex = 0

        mergedIndices.forEach(([start, end]) => {
            if (end <= partStart || start >= partEnd) return
            const hlStart = Math.max(start, partStart) - partStart
            const hlEnd = Math.min(end, partEnd) - partStart
            if (hlStart > lastIndex) {
                highlightedWord.push(part.slice(lastIndex, hlStart))
            }
            highlightedWord.push(
                <strong key={`hl-${index}-${hlStart}`}>
                    {part.slice(hlStart, hlEnd)}
                </strong>
            )
            lastIndex = hlEnd
        })

        if (lastIndex < part.length) {
            highlightedWord.push(part.slice(lastIndex))
        }

        highlightedPart.push(
            <React.Fragment key={index}>
                {highlightedWord.length > 0 ? highlightedWord : part}
            </React.Fragment>
        )
        currentIndex = partEnd
    })

    return <>{highlightedPart}</>
}
import { Link, useRouter, useNavigate } from '@tanstack/react-router'

export const TopBar = () => {
    const [search, setSearch] = useState('')
    const [showResults, setShowResults] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const resultsRef = useRef<HTMLDivElement>(null)
    const queryCache = useGlobalCache((state) => state.queryCache)

    const searchableModels = [
        'projects',
        'contacts',
        'invoices',
        'organisations',
        'suppliers',
        'roles',
        'costCentres',
        'organisationReports',
        'resourceScheduleReports',
        'revenueForecastReports',
        'staff',
    ]

    const modelMap = useMemo(() => {
        const map = new Map()
        searchableModels.forEach((model) => {
            map.set(model, [model])
            map.set(model.slice(0, -1), [model]) // Add singular form
        })
        map.set('pr', ['projects']) // Add shorthand for projects
        map.set('proj', ['projects']) // Add shorthand for projects
        map.set('cn', ['contacts']) // Add shorthand for contacts
        map.set('con', ['contacts']) // Add shorthand for contacts
        map.set('inv', ['invoices']) // Add shorthand for invoices
        map.set('org', ['organisations']) // Add shorthand for organisations
        map.set('sup', ['suppliers']) // Add shorthand for suppliers
        map.set('rol', ['roles']) // Add shorthand for roles
        map.set('cc', ['costCentres']) // Add shorthand for cost centres
        map.set('rep', [
            'organisationReports',
            'resourceScheduleReports',
            'revenueForecastReports',
        ]) // Add shorthand for organisation reports
        map.set('report', [
            'organisationReports',
            'resourceScheduleReports',
            'revenueForecastReports',
        ]) // Add shorthand for organisation reports
        map.set('st', ['staff']) // Add shorthand for staff
        return map
    }, [searchableModels])

    const allItems = useMemo(() => {
        return searchableModels.flatMap((model) =>
            queryCache(model).map((item: any) => ({
                model,
                item,
                label: getLabel[model](item),
            }))
        )
    }, [queryCache])

    const fuse = useMemo(
        () =>
            new Fuse(allItems, {
                keys: ['label'],
                threshold: 0.3,
                ignoreLocation: true,
            }),
        [allItems]
    )

    const handleSearch = useCallback((value: string) => {
        setSearch(value)
        setShowResults(value.length > 0)
    }, [])

    const filteredResults = useMemo(() => {
        if (search.length === 0) return []

        const [modelFilter, searchTerm] = search.split(':')

        if (modelMap.has(modelFilter) && searchTerm) {
            // Filter by specific model (plural or singular)
            const modelNames = modelMap.get(modelFilter)
            const modelItems = allItems.filter((item) =>
                modelNames.includes(item.model)
            )
            const modelFuse = new Fuse(modelItems, {
                keys: ['label'],
                threshold: 0.3,
                ignoreLocation: true,
            })
            return modelFuse.search(searchTerm).slice(0, 10)
        } else {
            // Search across all models
            return fuse.search(search).slice(0, 10)
        }
    }, [search, fuse, allItems, modelMap])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                resultsRef.current &&
                !resultsRef.current.contains(event.target as Node) &&
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setShowResults(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const { latestLocation } = useRouter()
    const navigate = useNavigate()
    return (
        <div className="w-full bg-background">
            <div className="border-b">
                <div className="flex items-center justify-between pl-6 pr-2 mx-auto">
                    <div className="pt-4">
                        <div className="font-semibold text-lg">
                            Project Awesome
                        </div>

                        <div className="flex justify-center mt-2">
                            <Tabs
                                defaultValue="details"
                                className="inline-block"
                                onValueChange={(value) => {
                                    navigate({
                                        to: latestLocation.pathname,
                                        search: (prev) => ({
                                            ...prev,
                                            tab: value,
                                        }),
                                    })
                                }}
                            >
                                <TabsList className="w-full justify-center">
                                    <TabsTrigger value="details">
                                        Details
                                    </TabsTrigger>
                                    <TabsTrigger value="forecasts">
                                        Forecasts
                                    </TabsTrigger>
                                    <TabsTrigger value="tasks">
                                        Tasks
                                    </TabsTrigger>
                                    <TabsTrigger value="changelog">
                                        Changelog
                                    </TabsTrigger>
                                    <TabsTrigger value="rates">
                                        Rates
                                    </TabsTrigger>
                                    <TabsTrigger value="notes">
                                        Notes
                                    </TabsTrigger>
                                    <TabsTrigger value="history">
                                        History
                                    </TabsTrigger>
                                    <TabsTrigger value="status">
                                        Status
                                    </TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>
                    </div>

                    <div className="flex flex-1 justify-end">
                        <div className="mx-4 flex-1 max-w-2xl relative">
                            <div className="relative">
                                <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                                <Input
                                    ref={inputRef}
                                    placeholder="Search all... (e.g., project:test or projects:test)"
                                    className="pl-8"
                                    value={search}
                                    onChange={(e) =>
                                        handleSearch(e.target.value)
                                    }
                                    onFocus={() => setShowResults(true)}
                                />
                            </div>
                            {showResults && (
                                <div
                                    ref={resultsRef}
                                    className="absolute z-10 w-full mt-1 bg-background border rounded-md shadow-lg"
                                >
                                    <Command>
                                        <CommandList>
                                            {search && search !== '' ? (
                                                <CommandEmpty>
                                                    No results found.
                                                </CommandEmpty>
                                            ) : null}
                                            {filteredResults.map(({ item }) => (
                                                <CommandItem
                                                    key={`${item.model}-${item.item.id}`}
                                                    value={item.item.id}
                                                    className="whitespace-pre-wrap"
                                                >
                                                    <HighlightExactMatch
                                                        text={item.label}
                                                        searchTerm={
                                                            search.includes(':')
                                                                ? search.split(
                                                                      ':'
                                                                  )[1]
                                                                : search
                                                        }
                                                    />
                                                    <span className="ml-2 text-xs text-muted-foreground">
                                                        (
                                                        {capitalCase(
                                                            pluralize(
                                                                item.model,
                                                                1
                                                            )
                                                        )}
                                                        )
                                                    </span>
                                                </CommandItem>
                                            ))}
                                        </CommandList>
                                    </Command>
                                </div>
                            )}
                        </div>

                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    variant="ghost"
                                    className="flex items-center space-x-2"
                                >
                                    <Avatar className="h-8 w-8">
                                        <AvatarFallback className="bg-primary text-primary-foreground">
                                            LC
                                        </AvatarFallback>
                                    </Avatar>
                                    <span>Le Corbusier</span>
                                    <MoreVertical className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                align="end"
                                className="w-[200px]"
                            >
                                <DropdownMenuItem>Profile</DropdownMenuItem>
                                <DropdownMenuItem>Settings</DropdownMenuItem>
                                <DropdownMenuItem>Logout</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </div>
    )
}
