import * as React from 'react';
import useEvent from '@react-hook/event';

function useHover(target, options = {}) {
  const {
    enterDelay,
    leaveDelay
  } = options;
  const timeout = React.useRef();
  const [hovering, setHovering] = React.useState(false);

  const toggle = which => {
    if (!canHover()) return;
    const delay = which ? enterDelay : leaveDelay;
    window.clearTimeout(timeout.current);

    function _ref() {
      return setHovering(which);
    }

    if (delay) {
      timeout.current = window.setTimeout(_ref, delay);
    } else {
      setHovering(which);
    }
  };

  useEvent(target, 'mouseenter', () => toggle(true));
  useEvent(target, 'mouseleave', () => toggle(false)); // Cleans up timeout on unmount

  function _ref2() {
    window.clearTimeout(timeout.current);
  }

  React.useEffect(() => _ref2, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return hovering;
}

const canHover = () => typeof window !== 'undefined' ? !window.matchMedia('(hover: none)').matches : false;

export default useHover;