import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import type { AppRouter } from '../../../server_node/src/trpc/router'

export const trpc = createTRPCProxyClient<AppRouter>({
    links: [
        httpBatchLink({
            url: process.env.REACT_APP_NODE_SERVER_URL + '/trpc',
            fetch(url, options) {
                return fetch(url, {
                    ...options,
                    credentials: 'include', // This is equivalent to withCredentials: true
                    headers: {
                        // ...options.headers,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    },
                })
            },
        }),
    ],
    transformer: undefined,
})
